/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { useEffect, useState } from "react";

import useCredits from "./hooks/useCredits";
import CreditsDoughnutChart from "./components/CreditsDoughnutChart/CreditsDoughnutChart";
import {
  generateCreditsItemBorderColor,
  generateCreditsItemColor,
} from "./functions";
import ViewHistoryLink from "./components/ViewHistoryLink/ViewHistoryLink";

import { creditsObjectType, CreditsStatuses } from "../types";

import CardWrapper from "../../CardWrapper/CardWrapper";
import DoughnutChartListItem from "../../components/DoughnutChartListItem/DoughnutChartListItem";
import { useStyles as dashboardStyles } from "../../styles";

import { creditsState, tableLoading } from "../../../../atoms/atoms";
import RectangleLoader from "../../../../shared/components/loaders/RectangleLoader";
import { useMediaQueries } from "../../../../shared";

const Credits = () => {
  const { isLoading } = useCredits();
  const { toSm } = useMediaQueries();
  const { t } = useTranslation();

  const isTableLoading = useRecoilValue(tableLoading);
  const creditsData = useRecoilValue(creditsState);

  const [hovered, setHovered] = useState<boolean>(false);
  const [hoveredElement, setHoveredElement] = useState<number | null>(null);

  const { doughnutChartListBox, doughnutChartContainer } = dashboardStyles({
    toSm,
  });

  const loading = isTableLoading || isLoading;

  useEffect(() => {
    !hovered && setHoveredElement(null);
  }, [hovered, hoveredElement]);

  const creditsObjectArray: creditsObjectType[] | null = creditsData && [
    {
      name: CreditsStatuses.Reserved,
      count: creditsData.reserved,
    },
    {
      name: CreditsStatuses.Available,
      count: creditsData.available,
    },
  ];

  const generateCreditList = () =>
    creditsObjectArray &&
    creditsObjectArray.map((i: creditsObjectType, index: number) => {
      return (
        <DoughnutChartListItem
          key={i.name}
          item={i}
          hoveredElement={hoveredElement}
          hovered={index === hoveredElement && hovered}
          backgroundColor={generateCreditsItemColor}
          borderColor={generateCreditsItemBorderColor}
          customStyle={{
            marginBottom: index === creditsObjectArray.length - 1 ? 0 : "12px",
          }}
        />
      );
    });

  return (
    <CardWrapper
      isTopSection
      cardTitle={t("Dashboard##credits")}
      isCreditsCard
      isLoading={loading}
    >
      {loading ? (
        <RectangleLoader
          width={"100%"}
          height={"100%"}
          testId="credits-card-loader"
        />
      ) : (
        creditsData && (
          <>
            <div css={css(doughnutChartContainer)}>
              <CreditsDoughnutChart
                creditsData={creditsData}
                setHovered={setHovered}
                setHoveredElement={setHoveredElement}
                creditsObjectArray={creditsObjectArray}
              />
              <div
                css={css([
                  doughnutChartListBox,
                  {
                    justifyContent: "center",
                  },
                ])}
              >
                {generateCreditList()}
              </div>
            </div>
            <ViewHistoryLink creditsData={creditsData} />
          </>
        )
      )}
    </CardWrapper>
  );
};

export default Credits;
