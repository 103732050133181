import { CSSObject } from "@emotion/react";

import { PaperContentHeights, PaperHeights, SideWidthType } from "./types";
import { DoughnutCenterItemsLineHeight, DoughnutTopAlignment } from "./config";

import { DashboardContainerHeight, ThemeColors } from "../../shared";

const gap = "8px";
const doubleGap = `16px`;

// only half of the 24px margin is visible,
// the other half is hidden by the scrollbar (scrollbarGutter: "stable both-edges" property in contentContainer)
// and visually looks like margin
const bothSidesMargin = "calc(12px * 2)";

const halfOfAGap = "calc(8px / 2)";
const creditsPaperHeightFrom1099 = (from1099: boolean) =>
  from1099 && PaperHeights.xlarge;

const calculateSideWidthToLg = ({ from1099, fromLg }: SideWidthType) =>
  from1099 && !fromLg
    ? `calc(50% - ${doubleGap})`
    : `calc(100% - ${bothSidesMargin})`;

const calculateLeftSideWidth = ({ from1099, fromLg }: SideWidthType) =>
  fromLg
    ? `calc(70% - ${doubleGap})`
    : calculateSideWidthToLg({ from1099, fromLg });

const calculateRightSideWidth = ({ from1099, fromLg }: SideWidthType) =>
  fromLg
    ? `calc(30% - ${doubleGap})`
    : calculateSideWidthToLg({ from1099, fromLg });

const calculateCreditsPaperHeight = ({ from1099, fromLg }: SideWidthType) =>
  fromLg
    ? PaperHeights.xs
    : creditsPaperHeightFrom1099(from1099) || PaperHeights.large;

const emptyCardWidth = (toSm: boolean, toMd: boolean, to1099: boolean) => {
  if (toSm) return "90%";
  if (toMd) return "55%";
  if (to1099) return "46%";
  return "70%";
};

interface UseStylesProps {
  colors?: ThemeColors;
  to1099?: boolean;
  from1099?: boolean;
  fromLg?: boolean;
  toSm?: boolean;
  toLg?: boolean;
  toMd?: boolean;
  diameter?: string;
  item?: any;
  fixedContentHeight?: boolean;
}

export const useStyles = ({
  colors,
  to1099 = false,
  from1099 = false,
  fromLg = false,
  toSm = false,
  toLg = false,
  toMd = false,
  diameter,
  item,
  fixedContentHeight,
}: UseStylesProps) =>
  ({
    dashboardContainer: {
      width: "100%",
      height: "100vh",
      backgroundColor: colors?.gray50,
    },
    contentContainer: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "center",
      width: "100%",
      gap: gap,
      overflowY: "auto",
      scrollbarGutter: "stable both-edges",
      height: DashboardContainerHeight,
      paddingBottom: "12px",
    },
    leftSide: {
      display: "flex",
      flexDirection: "column",
      gap: gap,
      width: calculateLeftSideWidth({ from1099, fromLg }),
    },
    rightSide: {
      display: "flex",
      flexDirection: "column",
      gap: gap,
      width: calculateRightSideWidth({ from1099, fromLg }),
    },
    dashboardPaper: {
      backgroundColor: colors?.white,
      borderRadius: gap,
      boxShadow:
        "0px 0px 2px 0px rgba(37, 42, 49, 0.16), 0px 1px 4px 0px rgba(37, 42, 49, 0.12)",
    },
    innerPaper: {
      width: fromLg ? `calc(50% - ${halfOfAGap})` : `100%`,
      gap: gap,
    },
    leftSidePapersHolder: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "center",
      width: "100%",
      gap: gap,
    },
    topSection: {
      height: PaperHeights.xs,
    },
    creditsPaper: {
      height: calculateCreditsPaperHeight({ from1099, fromLg }),
    },
    companyCardsPaper: {
      height: to1099 ? PaperHeights.sm : PaperHeights.xlarge,
    },
    dataCard: {
      height: to1099 ? PaperHeights.medium : PaperHeights.xlarge,
    },
    cardTitle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      margin: "24px 24px 16px 24px",
    },
    cardContentBox: {
      margin: "0 24px 24px 24px",
      // dynamically calculate height of a card content
      height: `calc(100% - ${PaperContentHeights.marginBottom} - ${PaperContentHeights.header})`,
    },
    emptyCardBox: {
      width: emptyCardWidth(toSm, toMd, to1099),
      height: "204px",
      textAlign: "center",
      margin: "0 auto",
      marginTop: "32px",
      marginBottom: "16px",
    },
    canvasHolder: {
      width: `${diameter}px`,
      height: `${diameter}px`,
      position: "relative",
    },
    doughnutChartCenterInfo: {
      position: "absolute",
      width: "50%",
      top: `${DoughnutTopAlignment}px`,
      left: "35px",
      textAlign: "center",
      lineHeight: `${DoughnutCenterItemsLineHeight}px`,
    },
    singleDoughnuChartItemBox: {
      display: "flex",
      marginBottom: "12px",
      marginRight: "16px",
    },
    doughnutChartListBox: {
      width: toSm ? "100%" : "184px",
      height: toSm || !fixedContentHeight ? "unset" : "166px",
      display: "flex",
      flexDirection: "column",
      flexWrap: toSm ? "unset" : "wrap",
      marginLeft: "24px",
    },
    doughnutChartContainer: { display: "flex" },
    doughnutChartItemStick: {
      width: "8px",
      height: "32px",
      marginRight: "8px",
      borderRadius: "2px",
      alignSelf: "center",
    },
    doughnutChartListItemInfoBlock: {
      display: "flex",
      flexDirection: "column",
    },
  } as CSSObject);
