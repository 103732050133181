/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useState } from "react";
import { useTranslation } from "react-i18next";

import { CookiesMainDescription } from "./components/CookiesMainDescription";
import CookiesModal from "./CookiesModal";
import { useStyles } from "./styles";
import { CookieButtonType } from "../types";
import Button, {
  BUTTON_COLORS,
  BUTTON_SIZES,
  BUTTON_VARIANTS,
} from "../../Button/Button";
import { COOKIE_NAMES, useMediaQueries } from "../../../shared";
import { useAuth } from "../../../context/Auth/AuthProvider";

const CookiesBanner = () => {
  const { changeCookieConsentStatus, cookieConsentStatus } = useAuth();

  const { t } = useTranslation();

  const { fromMd, fromLg } = useMediaQueries();

  const {
    cookiesBannerContainer,
    cookiesBannerDescription,
    cookiesBannerButtonsCSS,
  } = useStyles(fromMd, fromLg);

  const [showBanner, setShowBanner] = useState<boolean>(
    cookieConsentStatus.analytics !== "true" &&
      cookieConsentStatus.analytics !== "false"
  );

  const [isOpenCookiesDialog, setIsOpenCookiesDialog] = useState<boolean>(
    false
  );

  const handleReject = () => {
    changeCookieConsentStatus(COOKIE_NAMES.All, false);
    setShowBanner(false);
  };

  const COOKIE_CONSENT_ACTION_BUTTONS: CookieButtonType[] = [
    {
      color: BUTTON_COLORS.White,
      buttonText: t("Button##more options"),
      action: () => setIsOpenCookiesDialog(true),
    },
    {
      color: BUTTON_COLORS.Primary,
      buttonText: t("Button##reject all"),
      action: handleReject,
    },
    {
      color: BUTTON_COLORS.Primary,
      buttonText: t("Button##accept all"),
      action: handleReject, // until Google Analytics is prepared
    },
  ];

  return showBanner ? (
    <>
      <div data-testid="cookies-banner" css={css(cookiesBannerContainer)}>
        <div css={css(cookiesBannerDescription)}>
          <CookiesMainDescription />
        </div>
        <div css={css(cookiesBannerButtonsCSS)}>
          {COOKIE_CONSENT_ACTION_BUTTONS.map((button: CookieButtonType) => (
            <Button
              key={button.buttonText}
              fullWidth
              color={button.color}
              size={BUTTON_SIZES.Normal}
              variant={BUTTON_VARIANTS.TextOnly}
              onClick={button.action}
            >
              {button.buttonText}
            </Button>
          ))}
        </div>
      </div>
      <CookiesModal
        isOpen={isOpenCookiesDialog}
        onClose={setIsOpenCookiesDialog}
        setShowBanner={setShowBanner}
      />
    </>
  ) : null;
};

export default CookiesBanner;
