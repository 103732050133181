import { CSSObject } from "@emotion/react";
import { ThemeColors } from "../../../../shared";

interface StylesInterface {
  colors?: ThemeColors;
  marginBetweenSections?: boolean;
}

export const useStyles = ({ colors, marginBetweenSections }: StylesInterface) =>
  ({
    creditsHistoryPaperTitle: {
      display: "flex",
      justifyContent: "space-between",
    },
    creditsDiv: {
      marginBottom: "16px",
    },
    reservedDiv: {
      marginTop: "16px",
    },
    dialogContentSectionTitle: {
      marginBottom: "8px",
      alignItems: "center",
    },
    singleAcordionItemBlock: {
      display: "flex",
      marginBottom: "8px",
      alignItems: "center",
      wordBreak: "break-word",
      justifyContent: "space-between",
    },
    itemSeparator: { marginBottom: "16px" },
    creditsGeneralInfoPaper: {
      marginBottom: marginBetweenSections ? "24px" : "0",
      border: `1px solid ${colors?.gray200}`,
    },
  } as CSSObject);
