/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { SetStateAction, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

import DownloadDataTypesCheckboxes from "./DownloadDataTypesCheckboxes";
import {
  ActivityDates,
  CUSTOM_REPEAT_INPUT_REQUIREMENTS,
  REPEAT_NAME_VALUES,
  SCHEDULE_PAYLOAD_NAMES,
  ScheduleDataDto,
  ScheduleRepeatPeriod,
  TimeValue,
} from "../types";
import { useStyles } from "../styles";
import { useScheduleDateTimeValues } from "../hooks/useScheduleDateTimeValues";

import InputField from "../../InputField/InputField";
import QueryDropdown from "../../QueryDropdown/QueryDropdown";
import DatePicker from "../../DatePicker/DatePicker";
import Autocomplete from "../../Autocomplete/Autocomplete";

import {
  CombinedDataTypes,
  DATE_FORMATS,
  DriverDataTypes,
  ID_TYPE,
  INVALID_DATE,
  TIME_INPUT_WIDTH,
  inputFieldMarginBottom,
  useDateTime,
} from "../../../shared";
import { ApiResources } from "../../../api/resources";
import { ThemeContext } from "../../../context/theme/ThemeContextProvider";

interface ScheduleFormContentProps {
  inputError: any;
  inputValues: any;
  repeatValue: ScheduleRepeatPeriod | null;
  selectedDataTypes: CombinedDataTypes[];
  activityDates: ActivityDates;
  startDateTime: string | null;
  scheduleData?: ScheduleDataDto | null;
  isGlobalSchedule?: boolean;
  handleFormChange: (
    key: SCHEDULE_PAYLOAD_NAMES,
    value: string | ScheduleRepeatPeriod | CombinedDataTypes | null | boolean
  ) => void;
  handleTimeChange: (value: string) => void;
  validateScheduleName: (e: { target: { value: string } }) => void;
  validateStartDate: (
    error: string | null | SetStateAction<string | null>
  ) => void;
}

const ScheduleFormContent = ({
  inputError,
  inputValues,
  repeatValue,
  selectedDataTypes,
  activityDates,
  scheduleData,
  startDateTime,
  isGlobalSchedule,
  handleFormChange,
  handleTimeChange,
  validateScheduleName,
  validateStartDate,
}: ScheduleFormContentProps) => {
  const { colors } = useContext(ThemeContext);

  const { t } = useTranslation();
  const { formattedDate } = useDateTime();

  const { timeValues, getFormattedTimeInput } = useScheduleDateTimeValues();

  const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false);
  const [isTimeInputOpen, setIsTimeInputOpen] = useState<boolean>(false);
  const [isRepeatInputOpen, setIsRepeatInputOpen] = useState<boolean>(false);

  const { scheduleActivitiesDates } = useStyles();

  const handleStartDateChange = (value: string | null) => {
    const newDate = value === INVALID_DATE ? null : value;

    handleFormChange(
      SCHEDULE_PAYLOAD_NAMES.StartDate,
      `${newDate} ${startDateTime ?? ""}`
    );
  };

  const timeFilterOptions = (
    options: TimeValue[],
    { inputValue }: { inputValue: string }
  ) => {
    const matchingOptions = options.filter((option) =>
      option.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    const nonMatchingOptions = options.filter(
      (option) => !matchingOptions.includes(option)
    );
    return [...matchingOptions, ...nonMatchingOptions];
  };

  const handleTimeFieldBlur = (value: string) => {
    const formattedValue = getFormattedTimeInput(value);

    handleTimeChange(formattedValue ?? "");
  };

  const checkIfEqual = (option: any) => {
    const editedValue = startDateTime
      ? getFormattedTimeInput(startDateTime)
      : startDateTime;
    return option.label === editedValue;
  };

  return (
    <>
      <InputField
        testId="schedule-form-name-input-field"
        size="medium"
        fullWidth
        required
        readOnly={isGlobalSchedule || isDatePickerOpen}
        errorText={inputError.name ?? ""}
        onBlur={validateScheduleName}
        onChange={(e) =>
          handleFormChange(SCHEDULE_PAYLOAD_NAMES.Name, e.target.value)
        }
        defaultValue={
          scheduleData ? scheduleData[SCHEDULE_PAYLOAD_NAMES.Name] : undefined
        }
        value={
          isGlobalSchedule && !scheduleData
            ? inputValues[SCHEDULE_PAYLOAD_NAMES.Name]
            : undefined
        }
        labelLeft={t("Dialog##name")}
        placeholder={t("Dialog##enter here")}
        tooltipText={
          isGlobalSchedule ? t("Dialog##field cannot be changed") : undefined
        }
        customStyle={inputFieldMarginBottom}
      />

      <div css={css(scheduleActivitiesDates, inputFieldMarginBottom)}>
        <DatePicker
          key="schedule-date-field"
          label={t("Schedules##start date")}
          disablePast
          open={isDatePickerOpen}
          onClose={() => setIsDatePickerOpen(false)}
          onOpen={() => {
            setIsTimeInputOpen(false);
            setIsDatePickerOpen(true);
          }}
          initialDate={
            scheduleData
              ? formattedDate(
                  scheduleData[SCHEDULE_PAYLOAD_NAMES.StartDate],
                  DATE_FORMATS.DateHoursMinutes
                )
              : inputValues[SCHEDULE_PAYLOAD_NAMES.StartDate]
          }
          setDate={handleStartDateChange}
          textFieldParams={{
            required: true,
          }}
          externalErrorHandle={validateStartDate}
          customStyle={{ flex: 1 }}
        />

        <Autocomplete
          data-testid="start-date-time-input-field"
          labelLeft={t("Dialog##time")}
          size="medium"
          isRequired
          freeSolo
          forcePopupIcon
          disableClearable
          width={TIME_INPUT_WIDTH}
          readOnly={isDatePickerOpen}
          open={isTimeInputOpen}
          onOpen={() => {
            setIsDatePickerOpen(false);
            !isDatePickerOpen && setIsTimeInputOpen(true);
          }}
          onClose={() => {
            setIsTimeInputOpen(false);
          }}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={checkIfEqual}
          options={timeValues}
          onInputChange={(_, newInputValue) => {
            handleTimeChange(newInputValue);
          }}
          inputValue={startDateTime ?? ""}
          textFieldParams={{
            onBlur: (e: any) =>
              isTimeInputOpen && handleTimeFieldBlur(e.target.value),
            placeholder: DATE_FORMATS.HoursMinutes.toUpperCase(),
          }}
          filterOptions={timeFilterOptions}
          customStyle={inputError.startDate && { marginBottom: "26px" }}
        />
      </div>

      <QueryDropdown
        data-testid="repeat-period-dropdown"
        resource={ApiResources.Repeat}
        idType={ID_TYPE.Repeat}
        fullWidth
        isRequired
        disableClearable
        size="medium"
        value={repeatValue}
        readOnly={isDatePickerOpen}
        open={isRepeatInputOpen}
        onOpen={() => {
          setIsDatePickerOpen(false);
          !isDatePickerOpen && setIsRepeatInputOpen(true);
        }}
        onClose={() => {
          setIsRepeatInputOpen(false);
        }}
        change={(_, val) =>
          handleFormChange(SCHEDULE_PAYLOAD_NAMES.Repeat, val)
        }
        labelLeft={t("Schedules##repeat")}
        textFieldParams={{ placeholder: t("Dialog##select") }}
        customStyle={{ marginBottom: "16px" }}
      />

      {repeatValue?.value === REPEAT_NAME_VALUES.Custom && (
        <div css={css(scheduleActivitiesDates, inputFieldMarginBottom)}>
          <Typography variant="body2" sx={{ color: colors.textSecondary }}>
            {t("Schedules##repeat every")}
          </Typography>
          <InputField
            testId="schedule-form-name-custom-repeat-field"
            size="medium"
            onChange={(e) =>
              handleFormChange(
                SCHEDULE_PAYLOAD_NAMES.CustomRepeat,
                e.target.value
              )
            }
            value={inputValues.customRepeat ?? ""}
            placeholder={`${CUSTOM_REPEAT_INPUT_REQUIREMENTS.Min}-${CUSTOM_REPEAT_INPUT_REQUIREMENTS.Max}`}
            css={css({ width: "108px" })}
          />

          <Typography variant="body2" sx={{ color: colors.textSecondary }}>
            {t("Schedules##days")}
          </Typography>
        </div>
      )}

      {!selectedDataTypes.includes(DriverDataTypes.DriverCard) && (
        <DownloadDataTypesCheckboxes
          selectedDataTypes={selectedDataTypes}
          handleChange={handleFormChange}
          startDate={activityDates.startDate}
          repeatValue={repeatValue}
        />
      )}
    </>
  );
};

export default ScheduleFormContent;
