import { CreditsStatuses } from "../types";

import { ThemeColors } from "../../../../shared";

export const generateCreditsItemColor = (name: string, colors: ThemeColors) =>
  name === CreditsStatuses.Reserved ? colors.blue500 : colors.blue600;

export const generateCreditsItemBorderColor = (
  name: string,
  colors: ThemeColors
) => generateCreditsItemColor(name, colors);

export const checkIfOnlyOneOptionHasValue = (dataArray: number[]) => {
  return dataArray.filter((item: number) => item !== 0).length === 1;
};

export const checkIfPositiveNumber = (stringNumber: string | number) =>
  +stringNumber >= 0;

export const generateNumberStringWithPlusOrMinus = (
  number: string | number
) => {
  return +number >= 0 ? `+${number}` : `${number}`;
};
