import { Dispatch, SetStateAction, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { CookiesMainDescription } from "./components/CookiesMainDescription";
import { CookiesModalContent } from "./components/CookiesModalContent";
import { CookieButtonType } from "../types";
import {
  BUTTON_COLORS,
  BUTTON_SIZES,
  BUTTON_VARIANTS,
} from "../../Button/types";
import Button from "../../Button/Button";
import Dialog from "../../Dialog/Dialog";

import { useAuth } from "../../../context/Auth/AuthProvider";
import { COOKIE_NAMES } from "../../../shared";

interface CookiesModalProps {
  isOpen: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
  setShowBanner: Dispatch<SetStateAction<boolean>>;
}

const CookiesModal = ({
  isOpen,
  onClose,
  setShowBanner,
}: CookiesModalProps) => {
  const { changeCookieConsentStatus } = useAuth();
  const { t } = useTranslation();

  const handleCloseDialog = useCallback(() => {
    onClose(false);
  }, [onClose]);

  const handleOnlyEssentialCookies = () => {
    changeCookieConsentStatus(COOKIE_NAMES.All, false);
    handleCloseDialog();
    setShowBanner(false);
  };

  const COOKIE_MODAL_ACTION_BUTTONS: CookieButtonType[] = [
    {
      color: BUTTON_COLORS.Secondary,
      buttonText: t("Button##close"),
      action: handleCloseDialog,
    },
    {
      color: BUTTON_COLORS.Primary,
      buttonText: t("Button##accept"),
      action: handleOnlyEssentialCookies,
    },
  ];

  return (
    <Dialog
      title={t("Cookies##cookie modal title")}
      description={<CookiesMainDescription />}
      open={isOpen}
      close={handleCloseDialog}
      isTitleSeparator
      isActionsSeparator
      actions={
        <>
          {COOKIE_MODAL_ACTION_BUTTONS.map((button: CookieButtonType) => (
            <Button
              key={button.buttonText}
              fullWidth
              color={button.color}
              size={BUTTON_SIZES.Normal}
              variant={BUTTON_VARIANTS.TextOnly}
              onClick={button.action}
            >
              {button.buttonText}
            </Button>
          ))}
        </>
      }
    >
      <CookiesModalContent />
    </Dialog>
  );
};

export default CookiesModal;
