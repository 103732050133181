import { useTranslation } from "react-i18next";

import { SingleCookieItem } from "./SingleCookieItem";
import { CookieItem } from "../../types";
import { TOGGLE_SWITCH_LABEL_PLACEMENT } from "../../../ToggleSwitch";

export const CookiesModalContent = () => {
  const { t } = useTranslation();

  const COOKIE_ITEMS_LIST: CookieItem[] = [
    {
      haveItemSeparator: false,
      title: t("Cookies##essential cookies"),
      description: t("Cookies##essential cookies description"),
      isToggleDisabled: true,
      isToggleChecked: true,
      labelPlacement: TOGGLE_SWITCH_LABEL_PLACEMENT.Start,
      label: t("Cookies##always active"),
      onToggleChange: null,
    },
  ];

  return (
    <>
      {COOKIE_ITEMS_LIST.map(
        ({
          title,
          haveItemSeparator,
          description,
          label,
          labelPlacement,
          onToggleChange,
          isToggleDisabled,
          isToggleChecked,
          customStyle,
        }: CookieItem) => (
          <SingleCookieItem
            key={title}
            cookieTitle={title}
            cookieDescription={description}
            isItemSeparator={haveItemSeparator}
            switcherLabel={label}
            switcherLabelPlacement={labelPlacement}
            onToggleChange={onToggleChange}
            toggleDisable={isToggleDisabled}
            toggleChecked={isToggleChecked}
            customStyle={customStyle}
          />
        )
      )}
    </>
  );
};
